<template>
  <div class="text-center">
    <h4 class="mt-4">Ruta de Aprendizaje</h4>

    <MatterPresentation
      v-if="matter && show_header"
      :Matter="matter"
    ></MatterPresentation>

    <Header
      v-if="matter && !show_header"
      :Matter="matter"
      :section_id="section_id"
      :allows_crud="allows_crud"
      class="mb-1"
    ></Header>

    <div
      style="font-size: var(--secondary-font-size)"
      class="d-flex flex-row w-100 justify-content-around m-0 p-0"
    >
      <b-button
        size="sm"
        v-if="allows_crud"
        @click="goToSectionForm"
        v-can="'teaching.add_section'"
        class="btn-section"
        >Crear
        {{ $getVisibleNames("teaching.section", false, "Sección") }}</b-button
      >
      <template v-if="!to_print">
        <b-form-group
          label-cols="0"
          label-cols-sm="3"
          :label="$getVisibleNames('teaching.section', false, 'Sección')"
          label-class="label-custom"
          label-for="select-section"
          class="w-25 m-0 p-0"
        >
          <b-form-select
            id="select-section"
            v-model="section_id"
            :options="userSectionList"
            value-field="id"
            text-field="name"
            style="font-size: var(--secondary-font-size)"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-sm="4"
          label-class="label-custom"
          :label="$getVisibleNames('teaching.ramicro', false, 'RA Micro')"
          label-for="input-study-unit"
          class="w-25 m-0 p-0"
        >
          <b-form-select
            id="input-study-unit"
            v-if="matter"
            v-model="study_unit_id"
            :options="filteredStudyUnits"
            value-field="id"
            text-field="name"
            style="font-size: var(--secondary-font-size)"
            class="study-unit-select"
          ></b-form-select>
        </b-form-group>
      </template>
      <template v-else>
        <div v-if="section">
          {{ $getVisibleNames("teaching.section", false, "Sección") }}
          <strong>{{ section.name }}</strong>
        </div>
        <div v-if="studyUnit">
          {{ $getVisibleNames("teaching.ramicro", false, "RA Micro") }}
          <strong>{{ studyUnit.name }}</strong>
        </div>
      </template>
    </div>
    <hr />
    <template v-if="studyUnit && section">
      <b-tabs content-class="mt-3 h-auto" v-model="selected_tab">
        <!-- <b-tab title="Evaluaciones">
          <div>
            <h4 class="mt-4">{{ studyUnit.name }}</h4>
          </div>
          <StudyUnitSection
            :key="`study-unit-${studyUnit.id}`"
            :study_unit_id="studyUnit.id"
            :section_id="section.id"
            :allows_crud="allows_crud"
            @SumativeEvaluationsUpdated="slotSumativeEvaluationsUpdated"
          ></StudyUnitSection>
        </b-tab> -->

        <b-tab
          :title="
            $getVisibleNames('teaching.sessionplanning', false, 'Planificación')
          "
        >
          <div>
            <h4 class="mt-4">{{ studyUnit.name }}</h4>
          </div>
          <SessionTable
            class="mt-3"
            :key="`session-table-${studyUnit.id}`"
            :study_unit_id="studyUnit.id"
            :section_id="section.id"
            :allows_crud="allows_crud"
            :DirectTeachingActivities="directTeachingActivities"
            :AutonomousTeachingActivities="autonomousTeachingActivities"
            :TeachingSupportResource="teaching_support_resource"
            :SumativeEvaluations="sumativeEvaluations"
          ></SessionTable>
        </b-tab>
      </b-tabs>
    </template>
    <template v-else-if="section && studyUnit == null">
      <b-tabs content-class="mt-3 h-auto" v-model="selected_tab">
        <!-- <b-tab title="Evaluaciones">
          <template v-for="study_unit in studyUnitList">
            <div :key="study_unit.id">
              <h4 class="mt-4">{{ study_unit.name }}</h4>
            </div>
            <StudyUnitSection
              :key="`study-unit-${study_unit.id}`"
              :study_unit_id="study_unit.id"
              :section_id="section.id"
              :allows_crud="allows_crud"
              @SumativeEvaluationsUpdated="slotSumativeEvaluationsUpdated"
            ></StudyUnitSection>
          </template>
        </b-tab> -->
        <b-tab
          :title="
            $getVisibleNames('teaching.sessionplanning', false, 'Planificación')
          "
        >
          <template v-for="study_unit in studyUnitList">
            <div :key="study_unit.id">
              <h4 class="mt-4">{{ study_unit.name }}</h4>
            </div>
            <SessionTable
              class="mt-3"
              :key="`session-table-${study_unit.id}`"
              :study_unit_id="study_unit.id"
              :section_id="section.id"
              :allows_crud="allows_crud"
              :DirectTeachingActivities="directTeachingActivities"
              :AutonomousTeachingActivities="autonomousTeachingActivities"
              :TeachingSupportResource="teaching_support_resource"
              :SumativeEvaluations="sumativeEvaluations"
            ></SessionTable>
          </template>
        </b-tab>
      </b-tabs>
    </template>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "Planification",
  components: {
    MatterPresentation: () =>
      import("@/components/teaching/MatterProgram/MatterPresentation"),
    Header: () => import("./Header"),
    // StudyUnitSection: () => import("./StudyUnitSection"),
    SessionTable: () => import("./SessionTable"),
  },
  props: {
    matter_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    to_print: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      study_unit_id: null,
      session_plannig_list: [],
      section_id: null,
      show_header: false,
      direct_teaching_activities: [],
      autonomous_teaching_activity: [],
      teaching_support_resource: [],
      sumative_evaluations: [],
      selected_tab: 1,
    };
  },
  computed: {
    ...mapGetters({
      matters: names.MATTERS,
      studyUnits: names.STUDY_UNITS,
      learningResults: names.LEARNING_RESULTS,
      sessionPlannings: names.SESSION_PLANNINGS,
      sections: names.SECTIONS,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      evaluationCriterias: names.EVALUATION_CRITERIAS,
      procedureEvidences: names.PROCEDURE_EVIDENCES,
      taxonomy: names.TAXONOMY,
      user: "getUser",
      school: "getInstitution",
    }),
    matter() {
      if (!this.matter_id) return [];
      return this.egress_profiles_matters.find((x) => x.id == this.matter_id);
    },
    studyUnitList() {
      if (!this.matter) return [];
      const list = this.studyUnits.filter(
        (x) => x.matter == this.matter.matter
      );
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
    },
    filteredStudyUnits() {
      let list = [];
      if (!this.matter) return list;
      list = this.studyUnits.filter((x) => x.matter == this.matter.matter);
      list.forEach((x) => {
        if (!x.name.includes(`Unidad ${x.order}:`)) {
          x.name = `Unidad ${x.order}: ${x.name}`;
        }
      });
      list.push({
        id: null,
        name: "Mostrar Todos",
        matter: this.matter.matter,
      });
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
    },
    studyUnit() {
      return this.studyUnits.find((x) => x.id == this.study_unit_id);
    },
    learningResult() {
      if (!this.studyUnit) return null;
      return this.learningResults.find(
        (x) => x.id == this.studyUnit.learning_result
      );
    },
    section() {
      if (!this.section_id) return null;
      return this.sections.find((x) => x.id == this.section_id);
    },
    sectionList() {
      if (!this.matter) return [];
      return this.sections.filter(
        (x) => x.egress_profile_matter == this.matter.id
      );
    },
    userSectionList() {
      if (!this.school) return [];
      let list = [];
      if (this.user.is_professor) {
        this.user.sections_as_professor.forEach((section_id) => {
          const section = this.sectionList.find(
            (x) =>
              x.id == section_id && x.period == this.school.actual_period.id
          );
          if (section) list.push(section);
        });
      }
      if (this.user.is_student) {
        this.user.sections_as_student.forEach((section_id) => {
          const section = this.sectionList.find(
            (x) =>
              x.id == section_id && x.period == this.school.actual_period.id
          );
          if (section) list.push(section);
        });
      }
      return list;
    },
    directTeachingActivities() {
      return this.direct_teaching_activities;
    },
    autonomousTeachingActivities() {
      return this.autonomous_teaching_activity;
    },
    sumativeEvaluations() {
      return this.sumative_evaluations;
    },
  },
  methods: {
    goToSectionForm() {
      this.$router.push({
        name: "SectionView",
        params: {
          matter_id: this.matter_id,
          career_id: this.matter.career,
          plan_level: this.matter.plan_level,
        },
      });
    },
    fetchDirectTeachingActivities() {
      this.$restful
        .Get(`/mesh/direct-teaching-activity/?institution=${this.user.school}`)
        .then((response) => {
          this.direct_teaching_activities = response;
        });
    },
    fetchAutonomousTeachingActivities() {
      this.$restful
        .Get(
          `/mesh/autonomous-teaching-activity/?institution=${this.user.school}`
        )
        .then((response) => {
          this.autonomous_teaching_activity = response;
        });
    },
    fetchTeachingSupportResource(old_matter_id) {
      this.$restful
        .Get(`/teaching/support-resource/?matter=${old_matter_id}`)
        .then((response) => {
          this.teaching_support_resource = response;
        });
    },
    slotSumativeEvaluationsUpdated(sumative_evaluations) {
      this.sumative_evaluations = sumative_evaluations;
    },
  },
  watch: {
    studyUnit: function () {
      // this.studyUnit.performance_indicators.forEach((element) => {
      // if (
      //   this.performanceIndicators.findIndex((x) => x.id == element) == -1
      // ) {
      //   this.$store.dispatch(names.FETCH_PERFORMANCE_INDICATOR, element);
      // }
      // });
    },
    learningResult: function () {
      // if (this.learningResult) {
      //   this.learningResult.evaluation_criterias.forEach((element) => {
      //     if (
      //       this.evaluationCriterias.findIndex((x) => x.id == element) == -1
      //     ) {
      //       this.$store.dispatch(names.FETCH_EVALUATION_CRITERIA, element);
      //     }
      //   });
      // }
    },
    userSectionList() {
      if (this.userSectionList.length > 0)
        this.section_id = this.userSectionList[0].id;
    },
    studyUnitList() {
      if (this.studyUnitList.length > 0)
        this.study_unit_id = this.studyUnitList[0].id;
    },
  },

  created() {
    this.$store
      .dispatch(names.FETCH_USER, this.$store.state.user.id)
      .then((response) => {
        this.user.sections_as_professor = response.sections_as_professor;
      });
    if (this.matters.findIndex((x) => x.id == this.matter_id) == -1) {
      this.$store
        .dispatch(names.FETCH_EGRESS_PROFILE_MATTER, this.matter_id)
        .then((response) => {
          this.$store.dispatch(names.FETCH_SECTIONS, {
            egress_profile_matter_id: response.id,
          });
          this.fetchTeachingSupportResource(response.matter);
          this.$store.dispatch(names.FETCH_MATTER, response.matter);
          this.$store
            .dispatch(names.FETCH_STUDY_UNITS, response.matter)
            .then((response) => {
              if (response.length > 0) this.study_unit_id = response[0].id;
              this.$store.dispatch(names.FETCH_EVALUATION_CRITERIAS, {
                matter_id: response.matter,
              });
            });
        });
    }

    this.fetchDirectTeachingActivities();
    this.fetchAutonomousTeachingActivities();
    if (this.procedureEvidences.length == 0) {
      this.$store.dispatch(names.FETCH_PROCEDURE_EVIDENCES);
    }
    this.$store.dispatch(names.FETCH_CAMPUSES);
  },
  mounted() {},
};
</script>

<style scoped>
table {
  position: relative;
  width: 100%;
}
th {
  vertical-align: middle;
  border: 1px solid black;
  background-color: var(--primary-color);
  color: white;
  padding: 0px 5px;
  position: sticky;
  font-size: var(--secondary-font-size);
}
p {
  text-align: justify;
  margin: 0px 5px;
}
.btn-section {
  margin: 1.5em 0;
  padding: 0 0.6em;
}
.label-custom {
  margin-top: 1.5em !important;
  vertical-align: middle;
}
.study-unit-select {
  width: 100% !important;
  margin: 0 !important;
}
</style>